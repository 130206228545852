import App from './App.vue';
import router from '@/router';
import store from '@/store';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/index.less';


// sessionStorage.setItem('userId', '202203090917508810730001')
// sessionStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzEwODA4ODUsInVzZXJuYW1lIjoiMTM1Mjg4ODg4ODgifQ.VT6IilCAKSRiIcZithAARRzI1vGXJzhOs4-EQkP6rkY')
// sessionStorage.setItem('userId', '202203151533138882070001')
// sessionStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzExNjc1OTcsInVzZXJuYW1lIjoiMTM2NjY2NjY2NjYifQ.rJ8wtbgogejSzeYI7mQUVl7Ik1F1WfimYmHVhEq_V2E')

const app = createApp(App);

app.use(router);
app.use(store);
// 微信授权
import '@/utils/permission'

app.mount('#app');

