import router from '@/router/index'
import wechatAuth from '@/utils/wechatAuth'
import { getStore, setStore } from '@/utils/util'
import { CONFIG_STORAGE } from '@/utils/configs'
import { wxAuthorize } from '@/api/login'
import { useUserStore } from '@/store/modules/user'
import pinia from '@/store/index'
import { Toast } from 'vant'

// 设置APPID
const WX_APPID = import.meta.env.VITE_APP_WX_APP_ID
wechatAuth.setAppId(WX_APPID)
const userStore = useUserStore(pinia)
const whiteList = ['/bindAccount', '/bindResults', '/user', '/forgetPwd', '/registerB2B', '/registerB2C', '/registerResults', '/registerSource', '/notCert']
// 0 - 静默授权, 默认值，1 - 非静默授权
wechatAuth.setScope(1)

router.beforeEach(async (to, from, next) => {
  // 授权态 0为未授权 1为授权返回code 2为使用code获取openid成功
  let authStatus = getStore(CONFIG_STORAGE.AuthStatusKey) // 微信授权状态
  authStatus = authStatus === null || undefined ? 0 : authStatus
  const source = getStore("source") || 'B2C'
  switch (Number(authStatus)) {
    case 0:
      // 获取h5页面地址赋值给redirect_uri
      wechatAuth.setRedirect_uri(wechatAuth.processUrl())
      // 更改授权状态为1
      setStore(CONFIG_STORAGE.AuthStatusKey, 1)
      // 跳转到获取code访问链接
      window.location.href = wechatAuth.authUrl
      break
    case 1: {
      // 获取code值
      wechatAuth.returnFromWechat(window.location.href)
      const code = wechatAuth.code
      // 判断code是否存在
      if (!code) {
        setStore(CONFIG_STORAGE.AuthStatusKey, 0)
        next()
      }
      const res = await wxAuthorize({
        code,
        source
      })
      setStore(CONFIG_STORAGE.openId, res.userBindInfo?.openid)
      setStore(CONFIG_STORAGE.AuthStatusKey, 2)
      userStore.$patch({
        userBindInfo: res.userBindInfo,
      })
      setStore("bindToken", res.bindToken)
      setStore("bindStatus", res.bindStatus)
      if (res.bindStatus !== 'BINDING') {
        document.title = to.path === '/user' ? '我的' : '绑定账号'
        next(to.path === '/user' ? '/user' : '/bindAccount')
      }
      sessionStorage.setItem("openid", res.userBindInfo.openid)
      if (res.info) {
        sessionStorage.setItem("token", res.token)
        sessionStorage.setItem("userId", res.info.userId)
        setStore("source", res.info.source)
        userStore.$patch({
          userDataInfo: res.info,
          source: res.info.source
        })
      }

    }

    case 2: {
      if (!userStore.userBindInfo.openid) {
        if (getStore('bindStatus') !== 'BINDING') {
          setStore(CONFIG_STORAGE.AuthStatusKey, 0)
          document.title = to.path === '/user' ? '我的' : '绑定账号'
          next(to.path === '/user' ? '/user' : '/bindAccount')
        }
        try {
          const res = await wxAuthorize({
            token: getStore('bindToken'),
            source
          })
          setStore("bindStatus", res.bindStatus)
          setStore("bindToken", res.bindToken)
          sessionStorage.setItem("token", res.token)
          sessionStorage.setItem("userId", res.info.userId)
          setStore("source", res.info.source)
          userStore.$patch({
            userBindInfo: res.userBindInfo,
            userDataInfo: res.info,
            source: res.info.source
          })
        } catch (e) {
          setStore(CONFIG_STORAGE.AuthStatusKey, 0)
          router.go(0)
        }
      }
      const userCertStatus = userStore.userDataInfo.authenticatorInfo.userCertStatus
      if (to.path === '/bindAccount' && getStore('bindStatus') == 'BINDING') {
        document.title = '我的'
        next('/user')
      } else if (whiteList.includes(to.path)) {
        document.title = to.meta.title
        next()
      } if (getStore('bindStatus') !== 'BINDING') {
        document.title = to.path === '/user' ? '我的' : '绑定账号'
        next(to.path === '/user' ? '/user' : '/bindAccount')
      } else if (['CERT_INIT', 'CERT_REFUSE', 'CERT_PAST', 'CERT_AUDITING'].includes(userCertStatus)) {
        document.title = 'whalet易鲸跨境'
        next('/notCert')
      } else if (userCertStatus == "CERT_PASS") {
        document.title = to.meta.title
        next()
      }
      break
    }
    default:
      break
  }
})