import http, { Response } from '@/utils/http';


// 校验登录名
export function loginNameCheck(loginName: string) {
  return http.get(`/user/loginNameCheck/${loginName}`)
}
interface captchaGenerateRes {
  captchaId: string
  imageBase64Data: string
}
// 图片获取验证码
export function captchaGenerate() {
  return http.post(`common/captchaGenerate`)
}
// 获取验证码
export function smsVerifyCodeSend(params: any) {
  return http.post(`common/smsVerifyCodeSend`, params)
}
// 获取验证码
export function forgotPassword(params: any) {
  return http.post(`/user/forgotPassword`, params)
}
// 注册
export function userRegister(params: any) {
  return http.post(`/user/userRegister`, params)
}
// 用户微信授权
export function wxAuthorize(params: any) {
  return http.post(`/user/wxAuthorize`, params)
}
// 微信绑定
export function wxBind(params: any) {
  return http.post(`/user/wxBind`, params)
}