import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  // id: 'user', // id必填，且需要唯一。两种写法
  state: () => {
    return {
      source: '',
      userBindInfo: {
        city: "",
        country: "",
        headImgUrl: "",
        nickname: "",
        openid: "",
        privileges: null,
        province: "",
        sex: null,
        snapshotUser: null,
        unionId: null,
      },
      userDataInfo: {
        bindStatus: '',
        userId: '',
        permission: '',
        status: '',
        hasPayPassword: false,
        createTime: false,
        shops: [],
        cards: [],
        enableProduct: [],
        authenticatorInfo: {
          userAuthenticationBaseInfo: {
            enterpriseEnglishName: null,
            enterpriseName: null,
          },
          userAuthenticationIdentityInfos: [],
          userAuthenticationIdentityByCertRoleInfos: [],
          userAuthenticationLogInfos: {},
          bopsOperator: {
            id: '',
            name: ''
          },
          userCertSubject: '',
          userCertStatus: '',
          userCertType: '',
          userCertMethod: '',
          memo: '',
          level: '',
        },
        accounts: [],
        userFeeRuleInfo: {
          payeeRules: [],
          FeeRuleInfo: []
        },
        baseInfo: {
          nickname: '',
          Image: '',
          mobile: '',
          email: null,
          wechat: null,
          address: {
            nationality: null,
            province: null,
            city: null,
            street: null,
            addressStr: null,
          }
        },
        masterOperatorInfo: {
          activeInfo: {
            lastLoginIp: '',
            lastLoginTime: '',
            loginLastFailTime: '',
            loginPasswordErrorTimes: '',
            loginPasswordLastModifyTime: '',
            loginPasswordValidTime: '',
            payLastFailTime: '',
            payPasswordErrorTimes: '',
            payPasswordLastModifyTime: '',
            payPasswordValidTime: '',
          },
          baseInfo: {
            email: '',
            memo: '',
            mobile: '',
          },
          deleteFlag: '',
          loginName: '',
          loginPassword: '',
          operatorId: '',
          payPassword: '',
          salt: '',
          status: '',
          userOperatorRole: '',
        }
      },
    };
  }
});
