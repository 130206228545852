<template>
  <router-view v-slot="{ Component }">
    <keep-alive v-if="route.meta.keepAlive">
      <component :is="Component" />
    </keep-alive>
    <component v-else :is="Component" />
  </router-view>
</template>

<script setup lang="ts">
import debug from '@/utils/debug';
// import watermark from '@/utils/lib/watermark';
import copyPaste from '@/utils/lib/copy-paste';
const route = useRoute();
onMounted(() => {
  // 因为debug是存入localStorage中的，刷新页面会从localStorage取出，根据debug控制是否隐藏
  debug.init();

  // const { username = '', mobile = '' } = auth.getUser();

  copyPaste.disable();
});

onBeforeUnmount(() => {
  copyPaste.enable();
});
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
</style>
