
import { Toast } from "vant";
import * as math from 'mathjs';

export const arithmetic = {
  //加法

  add(a: number | string, b: number | string): number | string {
    return math.format(math.add(math.bignumber(a), math.bignumber(b)));
  },

  //减法

  subtract(a: number | string, b: number | string): number | string {
    return math.format(math.subtract(math.bignumber(a), math.bignumber(b)));

  },

  // 乘法

  multiply(a: number | string, b: number | string): number | string {
    // return math.format(math.multiply(math.bignumber(a), math.bignumber(b)));
    return math.multiply(math.bignumber(a), math.bignumber(b)).toString();

  },

  // 除法

  divide(a: number | string, b: number | string): number | string {
    return math.format(math.divide(math.bignumber(a), math.bignumber(b)));
  }

}
/**
 * 得到本周一、本周日、上周一、上周日的时间
 * 
 * return
 * {
 *   "thisWeekMonday": "2022-09-12",
 *   "thisWeekSunday": "2022-09-18",
 *   "lastWeekMonday": "2022-09-05",
 *   "lastWeekSunday": "2022-09-11"
 * }
 */
export function getMondayAndSunday() {
  var today = new Date();

  //构建当前日期,格式：2022-08-22 00:00:00
  var year = today.getFullYear(); //本年 
  var month = today.getMonth() + 1; //本月
  var day = today.getDate(); //本日
  var newDate = new Date(year + "-" + month + "-" + day + " 00:00:00"); //年月日拼接

  var nowTime = newDate.getTime(); //当前的时间戳
  var weekDay = newDate.getDay(); //当前星期 0.1.2.3.4.5.6 【0 = 周日】

  var oneDayTime = 24 * 60 * 60 * 1000; //一天的总ms

  // 当前星期减去天数，如今天为周五，则本周一为周五的时间戳减去4天的时间戳。但周日特殊，周一至周六是周几的到的weekDay就是几，但是周日的到的为0，需特殊处理
  var thisWeekMondayTime = (1 - weekDay) * oneDayTime + nowTime; //本周一的时间戳
  if (weekDay == 0) {
    // weekDay = 0 为周日，此时本周一时间为周日减去6天的时间
    thisWeekMondayTime = nowTime - 6 * oneDayTime
  }

  var thisWeekSundayTime = thisWeekMondayTime + 6 * 24 * 60 * 60 * 1000 // 本周日

  var lastWeekMondayTime = thisWeekMondayTime - 7 * oneDayTime // 上周一
  var lastWeekSundayTime = thisWeekMondayTime - oneDayTime // 上周日

  var res = {
    thisWeekMonday: format(new Date(thisWeekMondayTime), 'yyyy-MM-dd'),
    thisWeekSunday: format(new Date(thisWeekSundayTime), 'yyyy-MM-dd'),
    lastWeekMonday: format(new Date(lastWeekMondayTime), 'yyyy-MM-dd'),
    lastWeekSunday: format(new Date(lastWeekSundayTime), 'yyyy-MM-dd'),
  }
  return res;
}
/**
 * 得到本月第一天、本月最后一天、上月第一天、上月最后一天日期
 * return
 *  {
 *	  "thisMonthFirstDay": "2022-09-01",
 *	  "thisMonthLastDay": "2022-09-30",
 *	  "lastMonthFirstDay": "2022-08-01",
 *	  "lastMonthLastDay": "2022-08-31"
 *  }
 */
export function getMonthFirstDayAndLastDay() {
  var date = new Date()
  // 值得注意的是，如果day取值为0，意味着取的是date时间的上一个月的最后一天，简单理解：date当前月的第一天，再减去一天
  var thisMonthFirstDay = date.setDate(1) // 本月第一天
  var thisMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0) // 本月最后一天

  var lastMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1) // 上月第一天

  var lastMonthLastDay = date.setDate(0) // 上月最后一天
  // var day = new Date(date.getFullYear(), date.getMonth(), 0).getDate()
  // var lastMonthLastDay = new Date(date.getFullYear(), date.getMonth() - 1, day) // 上月最后一天
  var res = {
    thisMonthFirstDay: format(new Date(thisMonthFirstDay), 'yyyy-MM-dd'),
    thisMonthLastDay: format(thisMonthLastDay, 'yyyy-MM-dd'),
    lastMonthFirstDay: format(lastMonthFirstDay, 'yyyy-MM-dd'),
    lastMonthLastDay: format(new Date(lastMonthLastDay), 'yyyy-MM-dd'),
  }
  return res
}
export function format(date: Date, fmt: string): string {
  var o = {
    "M+": date.getMonth() + 1,                 //月份 
    "d+": date.getDate(),                    //日 
    "h+": date.getHours(),                   //小时 
    "m+": date.getMinutes(),                 //分 
    "s+": date.getSeconds(),                 //秒 
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
    "S": date.getMilliseconds()             //毫秒 
  };
  //(y+)匹配多个y，比如yyyy
  if (/(y+)/.test(fmt)) {
    // RegExp.$1是RegExp的一个属性,指的是与正则表达式匹配的第一个 子匹配(以括号为标志)字符串
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

export function _isMobile() {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}
// 获取assets静态资源
export const getAssetsFile = (url: string) => {
  return new URL(`../assets/${url}`, import.meta.url).href;
};

export function copyText(data: any) {
  const url = data;
  const oInput = document.createElement('textarea');
  oInput.value = url;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象;
  document.execCommand("copy"); // 执行浏览器复制命令
  Toast('复制成功');
  oInput.remove()
}
/*
 * @name: util.js
 * @Descripttion: 存储localStorage
 * @Author: Sando
 */

/**
* 存储localStorage
*/
export const setStore = (name: string, content: any) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = (name: string) => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = (name: string) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 清除localStorage
 * @param key
 * @returns {any}
 */
export const storeClear = () => {
  window.localStorage.clear()
}

interface money {
  amount: string | number
  cent?: string | number
  currency: string
}

export function computeCent(data: money): money {
  if (['JPY', 'KRW'].includes(data.currency))
    data.cent = data.amount
  else data.cent = arithmetic.multiply(data.amount, 100)
  return data
}
export function checkMoney(data: any = {}): void {
  if (Array.isArray(data)) {
    data.forEach(o => checkMoney(o))
  }
  Object.keys(data).forEach((key: string) => {
    if (key.toLowerCase().includes('amount') && data[key].hasOwnProperty('amount') && data[key].hasOwnProperty('currency')) {
      computeCent(data[key])
    } else if (data[key] && typeof data[key] === 'object') {
      checkMoney(data[key])
    }
  })
}

export const toPhoneCall = (mobile: number | string) => {
  const url = `tel:${mobile}`;
  window.location.href = url;
};

/**
 * 动态设置css全局变量实现旋转
 * transform: rotate(var(--image-rotate))
 * @param deg 旋转角度
 * @param prop css变量, 默认'--image-rotate'
 */
export const setRotate = (deg: string, prop = '--image-rotate') => {
  let rotate = document.documentElement.style.getPropertyValue(prop) || '0deg';
  if (typeof deg === 'string') {
    rotate = deg;
  } else {
    rotate = parseInt(rotate) + 90 + 'deg';
  }
  document.documentElement.style.setProperty(prop, rotate);
};
