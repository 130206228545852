import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import type { Response } from './types';
// import { auth } from '@/utils';
import { Toast } from 'vant';
import router from '@/router/index'
import qs from "qs"
import { checkMoney } from '@/utils/util'
import { useUserStore } from '@/store/modules/user'
import { getStore, setStore } from '@/utils/util'
import pinia from '@/store/index'
import { wxAuthorize } from '@/api/login'

const userStore = useUserStore(pinia)

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
});

const pending = new Map()
/**
 * 添加请求
 * @param {Object} config
 */
const addPending = (config: AxiosRequestConfig) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(url, cancel)
    }
  })
}
/**
 * 移除请求
 * @param {Object} config
 */
const removePending = (config: AxiosRequestConfig) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
}

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}

// axios实例拦截请求
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    removePending(config) // 在请求开始前，对之前的请求做检查取消操作
    addPending(config) // 将当前请求添加到 pending 中
    config.headers = {
      ...config.headers,
      // ...auth.headers(), // 你的自定义headers，如token等
    };
    const token = sessionStorage.getItem('token')
    if (token) {
      config.headers['X-Access-Token'] = token;
    }
    if (config.data) {
      checkMoney(config.data)
      config.data.userId = config.data.userId ? config.data.userId : sessionStorage.getItem("userId")
      config.data.source = config.data.source ? config.data.source : getStore('source')
      // config.data.partnerId = store.state.source === 'Partner' ? sessionStorage.getItem("userId") : undefined
    } else if (config.params) {
      checkMoney(config.params)
      config.params.userId = config.params.userId ? config.params.userId : sessionStorage.getItem("userId")
      config.params.source = config.params.source ? config.params.source : getStore('source')

      // config.params.partnerId = store.state.source === 'Partner' ? sessionStorage.getItem("userId") : undefined
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse<Response>) => {
    removePending(response) // 在请求结束后，移除本次请求
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    const { code, detail, status } = response.data;
    if (['EMPTY_CREDENTIAL', 'INVALID_CREDENTIAL', 'EXPIRED_CREDENTIAL', 'UNAUTHENTICATED_ERROR'].includes(code)) {
      // userStore.$patch({
      //   userBindInfo: {
      //     city: "",
      //     country: "",
      //     headImgUrl: "",
      //     nickname: "",
      //     openid: "",
      //     privileges: null,
      //     province: "",
      //     sex: null,
      //     snapshotUser: null,
      //     unionId: null,
      //   }
      // })
      wxAuthorize({
        source: getStore('source'),
        token: getStore('bindToken'),
      }).then((res) => {
        sessionStorage.setItem('token', res.token);
        sessionStorage.setItem('userId', res.info.userId);
        setStore('source', res.info.source);
        userStore.$patch({
          userDataInfo: res.info,
          source: res.info.source,
        });
      });
      Toast.fail('刷新token中，请稍后重试！');
    } else if (status !== 'success') {
      if (!['/user/wxBind', '/user/userRegister'].includes(response.config.url))
        Toast.fail(detail);
      return Promise.reject(response);
    }
    return response;
  },
  // 非2xx时触发
  (error: AxiosError) => {
    Toast.fail('Network Error...');
    return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
