/*
 * @name: configs.js
 * @Descripttion: 定义localStorage常量
 * @Author: Sando
 */

const CONFIG_STORAGE = {
  openId: 'WX_OPENID', // 微信openId
  AuthStatusKey: '3c759a63531aba7b7c8eed5e5cdb5574' // 服务号微信授权标识
}

export { CONFIG_STORAGE }