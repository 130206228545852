import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/user'
  },
  {
    path: '/bindAccount',
    name: 'bindAccount',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/bindAccount/index.vue'),
    meta: {
      title: '绑定账号'
    }
  },
  {
    path: '/bindResults',
    name: 'bindResults',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/bindAccount/results.vue'),
    meta: {
      title: 'whalet易鲸跨境'
    }
  },
  {
    path: '/payee',
    name: 'payee',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/payee/index.vue'),
    meta: {
      title: '收款记录', keepAlive: true
    }
  },
  {
    path: '/payeeInfo/:id',
    name: 'payeeInfo',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/payee/details.vue'),
    meta: {
      title: '详情', keepAlive: true
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/payment/index.vue'),
    meta: {
      title: '付款记录', keepAlive: true
    }
  },
  {
    path: '/paymentInfo/:id',
    name: 'paymentInfo',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/payment/details.vue'),
    meta: {
      title: '详情', keepAlive: true
    }
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/withdrawal/index.vue'),
    meta: {
      title: '提现记录',
      keepAlive: true
    }
  },
  {
    path: '/withdrawalInfo/:id',
    name: 'withdrawalInfo',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/withdrawal/details.vue'),
    meta: {
      title: '详情',
      keepAlive: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/user/index.vue'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/notCert',
    name: 'notCert',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/user/notCert.vue'),
    meta: {
      title: 'whalet易鲸跨境'
    }
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/forgetPwd/index.vue'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/registerB2B',
    name: 'registerB2B',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/register/register.vue'),
    meta: {
      title: '外贸B2B账号'
    }
  },
  {
    path: '/registerB2C',
    name: 'registerB2C',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/register/register.vue'),
    meta: {
      title: '跨境电商账号'
    }
  },
  {
    path: '/registerResults',
    name: 'registerResults',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/register/results.vue'),
    meta: {
      title: 'whalet易鲸跨境'
    }
  },
  {
    path: '/registerSource',
    name: 'registerSource',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/register/registerSource.vue'),
    meta: {
      title: 'whalet注册'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
